import React from "react";

const DogIcon = () => (
  <g>
    <path d="M11.78,9H5.2L3.8,7.55A.76.76,0,1,0,2.72,8.63L4.12,10v6.58a.77.77,0,1,0,1.53,0V13.55h6.13v3.06a.77.77,0,1,0,1.53,0V10.42Z" fill="#fff" />
    <path d="M12.54,17.6a1,1,0,0,1-1-1V13.77H5.87v2.84a1,1,0,1,1-2,0V10.13L2.56,8.79a1,1,0,0,1-.29-.7,1,1,0,0,1,.29-.7,1,1,0,0,1,.7-.29,1,1,0,0,1,.7.29L5.29,8.73h6.58l1.66,1.6v6.28A1,1,0,0,1,12.54,17.6ZM5.43,13.32H12v3.29a.54.54,0,0,0,.54.54.55.55,0,0,0,.55-.54V10.52l-1.4-1.35H5.11L3.64,7.71a.55.55,0,0,0-.77,0,.54.54,0,0,0-.15.38.56.56,0,0,0,.15.39L4.34,9.94v6.67a.54.54,0,0,0,.54.54.55.55,0,0,0,.55-.54Z" fill="#000b25" />
    <path d="M12.15,8.45V5.7L13.28,7h1.59a.68.68,0,0,1,.68.69V8.94l-1.78,1Z" fill="#fff" />
    <path d="M13.77,10.14a.22.22,0,0,1-.15-.06L12,8.62a.21.21,0,0,1-.08-.17V5.7a.22.22,0,0,1,.15-.21.22.22,0,0,1,.24.06l1.07,1.22h1.49a.91.91,0,0,1,.91.91V8.94a.24.24,0,0,1-.12.2l-1.78,1A.23.23,0,0,1,13.77,10.14Zm-1.4-1.78L13.8,9.64l1.53-.83V7.68a.45.45,0,0,0-.46-.46H13.28a.25.25,0,0,1-.17-.08l-.74-.85Z" fill="#000b25" />
  </g>

);

export default (DogIcon);