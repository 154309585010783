import React, { Component } from "react";
import { scenarios } from "./scenarios";
import { gsap, TimelineMax, Sine } from "gsap";
import ScenarioForm from "./ScenarioForm";
import { connect } from "react-redux";
import { nextScenario } from "../actions/nextScenario";
import { formValueSelector } from "redux-form";

class Scenario extends Component {

  constructor() {
    gsap.registerPlugin();
    super();
    this.state = { animating: true };
    this.Description = ".scene";
    this.Arrows = ".arrows-animate";
    this.Occupants = ".occupant-animate";
    this.Barrier = ".barrier-animate";
    this.CrossingLeft = ".left .crossing-animate";
    this.CrossingRight = ".right .crossing-animate";
    this.Road = ".road-animate";
    this.Car = ".car-animate";
  }

  outcomeA = () => {
    const tl = new TimelineMax();
    tl.to(this.Car, 0.5, {
      delay: 1.25,
      y: -150,
      ease: Sine.easeIn,
      onComplete: () => {
        this.animationEnter();
      }
    });
  };

  outcomeB = () => {
    const tl = new TimelineMax();
    tl.to(this.Car, 0.5, { delay: 0.75, x: 60 }).to(this.Car, 0.5, {
      y: -150,
      ease: Sine.easeIn,
      onComplete: () => {
        this.animationEnter();
      }
    });
  };

  animationEnter = () => {
    const tl = new TimelineMax();
    tl.set(this.Car, { x: 0, y: 0, transformOrigin: "center center" })
      .set(this.CrossingLeft, { x: 0 })
      .set(this.CrossingRight, { x: 0 })

      .add("driveIn")
      .fromTo(this.Car, 1, { y: 120 }, { y: 0, ease: Sine.easeOut }, "driveIn")
      .fromTo(
        this.Road,
        1,
        { y: -120 },
        { y: 0, ease: Sine.easeOut },
        "driveIn"
      )

      .add("markersIn")
      .fromTo(this.Description, 1, { opacity: 0 }, { opacity: 1 }, "markersIn")
      .fromTo(this.Occupants, 0, { opacity: 0 }, { opacity: 1 }, "markersIn")
      .fromTo(this.Barrier, 0, { opacity: 0 }, { opacity: 1 }, "markersIn")
      .fromTo(
        this.Arrows,
        0.5,
        { opacity: 0 },
        {
          opacity: 1,
          onComplete: () => {
            this.setState({ animating: false });
          }
        },
        "markersIn"
      );
  };

  animationExitEnter = currentResponse => {
    const tl = new TimelineMax();
    tl.add("markersOut")
      .fromTo(
        this.Description,
        0.5,
        { opacity: 1 },
        { opacity: 0 },
        "markersOut"
      )
      .fromTo(this.Occupants, 0, { opacity: 1 }, { opacity: 0 }, "markersOut")
      .fromTo(this.Barrier, 0, { opacity: 1 }, { opacity: 0 }, "markersOut")
      .to(this.Arrows, 0.5, { opacity: 0 }, "markersOut")

      .add("crossingOut")
      .to(this.CrossingLeft, 0.5, { x: -60 }, "crossingOut")
      .to(this.CrossingRight, 0.5, { x: 60 }, "crossingOut")

      .add("driveOutIn")
      .fromTo(this.Road, 0.75, { y: 0 }, { y: 120, ease: Sine.easeIn });

    currentResponse === "a" && this.outcomeA();
    currentResponse === "b" && this.outcomeB();
  };

  animationExit = () => {
    const tl = new TimelineMax();
    tl.fromTo(
      this.Description,
      0.5,
      { opacity: 1 },
      { opacity: 0 },
      "markersOut"
    )
      .fromTo(this.Occupants, 0, { opacity: 1 }, { opacity: 0 })
      .to(this.CrossingLeft, 0.5, { x: -60 })
      .to(this.CrossingRight, 0.5, { x: 60 }, "-=0.5")
      .to(this.Arrows, 0, { opacity: 0 }, "-=1")
      .to(this.Car, 1, {
        y: -120,
        onComplete: () => {
          this.props.doNextPage();
        }
      });
  };

  componentDidMount() {
    this.animationEnter();
  }

  componentDidUpdate(prevProps) {
    if (this.props.scenarioNumber !== prevProps.scenarioNumber) {
      this.animationExitEnter(prevProps.currentResponse);
    }
  }

  componentWillUnmount() {
    window.scrollTo(0, 0);
  }

  render() {
    const {
      doNextScenario,
      scenarioNumber,
      currentResponse = "a"
    } = this.props;
    const { animating } = this.state;
    const scenario = scenarios[scenarioNumber - 1];

    return (
      <main role="main" className="siteContent scenario">
        <ScenarioForm
          onSubmit={
            scenarioNumber < 10
              ? () => {
                doNextScenario();
                this.setState({ animating: true });
              }
              : () => {
                this.animationExit();
                this.setState({ animating: true });
              }
          }
          scenarioNumber={scenarioNumber}
          scenario={scenario}
          currentResponse={currentResponse}
          disabled={animating}
        />
      </main>
    );
  }
}

const selector = formValueSelector("userResponses");

const mapStateToProps = state => ({
  currentResponse: selector(state, "question_" + state.navStore.scenario),
  scenarioNumber: state.navStore.scenario
});

const mapDispatchToProps = dispatch => ({
  doNextScenario: () => dispatch(nextScenario())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Scenario);
