import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import fire from "../fire";

import { connect } from "react-redux";
import { nextPage } from "../actions/nextPage";
class AboutMe extends Component {
  render() {
    const { handleSubmit } = this.props;
    return (
      <main role="main" className="siteContent content clear">
        <div>
          <div className="panel">
            <h1>About you</h1>
            <p>To help with our analysis, please tell us a little about yourself</p>

            <div className="description">
              <h3 className="description-title">Why do we need this?</h3>
              <p>Driver's perceptions and attitudes can change according to a number of
              factors. By knowing how the results look by gender, age and region, we’re better able to
            create a robust and accurate analysis.</p>
            </div>

          </div>
          <form className="panel" onSubmit={handleSubmit}>
            <div className="input-wrapper">
              <label>Sex</label>
              <div className="genderSelect-wrapper">
                <Field
                  className="genderSelect-input"
                  id="male"
                  name="sex"
                  component="input"
                  type="radio"
                  value="male"
                  required
                />
                <label id="genderSelect-male" className="genderSelect" htmlFor="male">Male</label>

                <Field
                  className="genderSelect-input"
                  id="female"
                  name="sex"
                  component="input"
                  type="radio"
                  value="female" />
                <label id="genderSelect-female" className="genderSelect" htmlFor="female">Female</label>

              </div>
            </div>
            <div className="input-wrapper">
              <label htmlFor="age">Age</label>
              <Field name="age" component="select" required>
                <option value="">Select your age group</option>
                <option value="18-24">18-24</option>
                <option value="25-34">25-34</option>
                <option value="35-44">35-44</option>
                <option value="45-54">45-54</option>
                <option value="55+">55+</option>
              </Field>
            </div>

            <div className="input-wrapper">
              <label htmlFor="location">Location</label>
              <Field name="location" component="select" required>
                <option value="">Select your region</option>
                <option value="East Anglia">East Anglia</option>
                <option value="East Midlands">East Midlands</option>
                <option value="London">London</option>
                <option value="North East">North East</option>
                <option value="North West">North West</option>
                <option value="Northern Ireland">Northern Ireland</option>
                <option value="Scotland">Scotland</option>
                <option value="South East">South East</option>
                <option value="South West">South West</option>
                <option value="Wales">Wales</option>
                <option value="West Midlands">West Midlands</option>
                <option value="Yorkshire and the Humber">
                  Yorkshire and the Humber
              </option>
              </Field>
            </div>
            <button className="button" type="submit">
              Get results
          </button>
          </form>
        </div>
      </main >
    );
  }
}

AboutMe = reduxForm({
  // a unique name for the form
  form: "userResponses",
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true // <------ unregister fields on unmount
})(AboutMe);

const mapStateToProps = state => ({
  scenarioNumber: state.navStore.scenario
  //responses: state.form.userResponses.values
});

const mapDispatchToProps = dispatch => ({
  onSubmit: responses => {
    fire
      .database()
      .ref("responses")
      .push(responses);
    dispatch(nextPage());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AboutMe);
