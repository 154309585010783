import React, { Component } from "react";
import { connect } from "react-redux";

class AboutProject extends Component {
  render() {
    const { doNextPage } = this.props;
    return (
      <main role="main" className="siteContent content">
        <div>
          <h1>About this project</h1>

          <p>As cars become more autonomous, one of the largest dilemmas facing manufacturers and lawmakers is an ethical one.</p>

          <p>In a situation where a self-driving car must decide who it should protect in the event of an accident,
        some might take issue with a machine making moral decisions on behalf of a human.</p>

          <p>Inspired by an ongoing experiment at MIT, this simulation is designed to collect human responses to
          such dilemmas to better understand how we expect self-driving cars to behave. The responses
        gathered from this experiment will help inform our next industry report on autonomous cars.</p>

          <button className="button" onClick={() => doNextPage()}>Next</button>
        </div>
      </main>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AboutProject);
