import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";

import { recalculatePrefs } from "../actions/recalculatePrefs";

import SceneSVG from "../modules/SceneSVG";

class ScenarioForm extends Component {
  componentWillMount() {
    this.props.doRecalculatePrefs();
  }
  componentWillReceiveProps() {
    this.props.doRecalculatePrefs();
  }

  render() {
    const {
      handleSubmit,
      scenarioNumber,
      scenario,
      disabled,
      currentResponse,
      change
    } = this.props;

    return (
      <div className="sceneWrapper">

        <div className="progressBar" role="presentation">
          <div className="progressBar-bar" style={{ width: `${scenarioNumber * 10 - 10}%` }} ></div>
        </div>

        <SceneSVG
          selectOption={change}
          scenario={scenario}
          chosenOutcome={currentResponse}
          scenarioNumber={scenarioNumber}
        />

        <form className="scene" onSubmit={handleSubmit}>
          <div className="sceneContent">
            <h3 className="progressCounter"><span>{scenarioNumber}</span> of 10</h3>

            <div className="tabs" role="tablist">
              <label
                tabIndex="0"
                htmlFor="outcomeA"
                id="outcomeA-tab"
                className="tab"
                aria-selected={currentResponse === "a"}
              >
                A
              </label>

              <Field
                className="outcomeA"
                id="outcomeA"
                name={"question_" + scenarioNumber}
                component="input"
                type="radio"
                value="a"
                required={true}
                role="tab"
                title={scenario.a.description}
              />

              <label
                tabIndex="0"
                htmlFor="outcomeB"
                id="outcomeB-tab"
                className="tab"
                aria-selected={currentResponse === "b"}
              >
                B
              </label>

              <Field
                className="outcomeB"
                id="outcomeB"
                name={"question_" + scenarioNumber}
                component="input"
                type="radio"
                value="b"
                required={true}
                role="tab"
                title={scenario.b.description}
              />
            </div>

            <h1
              id="#scenario-description"
              className="scenarioDesc"
              dangerouslySetInnerHTML={{ __html: scenario.description }}
            />

            <div id="#outcome-description" className="outcomeDesc description">
              <h3 className="description-title">
                Outcome {currentResponse === "a" ? "A" : "B"}
              </h3>

              {currentResponse === "a" && (
                <p
                  dangerouslySetInnerHTML={{ __html: scenario.a.description }}
                />
              )}

              {currentResponse === "b" && (
                <p
                  dangerouslySetInnerHTML={{ __html: scenario.b.description }}
                />
              )}
            </div>

            <button className="button" type="submit" disabled={disabled}>
              <span className="currentResponse-text">Choose Outcome</span>{" "}
              <span className="currentResponse-indicator">
                <span className="currentResponse-indicator-text">
                  {currentResponse === "a" ? "A" : "B"}
                </span>
              </span>
            </button>
          </div>
        </form>
      </div >
    );
  }
}

const initialValues = {
  question_1: "a",
  question_2: "a",
  question_3: "a",
  question_4: "a",
  question_5: "a",
  question_6: "a",
  question_7: "a",
  question_8: "a",
  question_9: "a",
  question_10: "a",
  inCarVsRoadUser: 0,
  fewVsMany: 0,
  youngVsOld: 0,
  humanVsAnimal: 0
};

ScenarioForm = reduxForm({
  form: "userResponses",
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true // <------ unregister fields on unmount
})(ScenarioForm);

const mapStateToProps = (state, { scenarioNumber }) => ({
  initialValues: initialValues
});

const mapDispatchToProps = dispatch => ({
  doRecalculatePrefs: () => dispatch(recalculatePrefs())

  // doNextScenario: () => dispatch(nextScenario())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScenarioForm);
