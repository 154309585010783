import React, { Component } from "react";
import { connect } from "react-redux";
import AdultIcon from "./../assets/AdultIcon";
import ChildIcon from "./../assets/ChildIcon";
import ElderlyIcon from "./../assets/ElderlyIcon";
import DogIcon from "./../assets/DogIcon";


class Instructions extends Component {
  render() {
    const { doNextPage } = this.props;
    return (
      <main role="main" className="siteContent content clear">
        <div className="panel">
          <h1>Instructions</h1>
          <p>In the following scenarios, imagine the self-driving car’s brakes have failed.</p>
          <p>You must decide who the car should be programmed to protect.</p>
          <p><strong>Every decision you make is final.</strong></p>

        </div>

        <div className="panel">

          <div className="tabInstructions">
            <h4 className="tabInstructions-title">Use the A/B tabs to view each outcome</h4>
            <div className="tab is-active">A</div>
            <div className="tab">B</div>
          </div>

          <ol className="occupantsList">
            <li className="occupantsList-item">
              <svg className="occupantsList-item-icon" viewBox="0 0 20 20"><rect className="occupantIcon-container" y="1.79" width="18.26" height="18.26" rx="0.5" ry="0.5" fill="#1cf3a0" /><AdultIcon /></svg>
              <h3 id="adult">Adult</h3>
            </li>
            <li className="occupantsList-item">
              <svg className="occupantsList-item-icon" viewBox="0 0 20 20"><rect className="occupantIcon-container" y="1.79" width="18.26" height="18.26" rx="0.5" ry="0.5" fill="#1cf3a0" /><ElderlyIcon /></svg>
              <h3 id="adult">Elderly person</h3>
            </li>
            <li className="occupantsList-item">
              <svg className="occupantsList-item-icon" viewBox="0 0 20 20"><rect className="occupantIcon-container" y="1.79" width="18.26" height="18.26" rx="0.5" ry="0.5" fill="#1cf3a0" /><ChildIcon /></svg>
              <h3 id="adult">Child</h3>
            </li>
            <li className="occupantsList-item">
              <svg className="occupantsList-item-icon" viewBox="0 0 20 20"><rect className="occupantIcon-container" y="1.79" width="18.26" height="18.26" rx="0.5" ry="0.5" fill="#1cf3a0" /><DogIcon /></svg>
              <h3 id="dogDe">Dog</h3>
            </li>
          </ol>

          <button className="button" onClick={() => doNextPage()}>Begin simulation</button>
        </div>
      </main >
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Instructions);
