import React from "react";

const CarRotate = () => (

  <g className="carIntro">
    <path d="M76.07,47c.14,6.56.11,13.13,0,19.7-.11,7.12.6,14.4.13,21.49-.37,5.6-.54,10.29-6.7,12.18a36.33,36.33,0,0,1-18.52.12c-6-1.6-6.06-6-6.84-11.18a64.53,64.53,0,0,1-.29-12.59c.48-9.87-.23-19.9,0-29.82l-.2-8.8a49.48,49.48,0,0,1,.25-7.2c.27-2.31.88-6.18,2.22-8.11C49,18.68,57,18.25,61.4,18.41c3.6.13,11.54,1.08,12.82,5.25a38.36,38.36,0,0,1,2,14.39Z" fill="#00112b" />
    <path d="M60.21,102.13A36.82,36.82,0,0,1,50.85,101c-5.8-1.55-6.37-5.6-7-10.3l-.18-1.29a65.67,65.67,0,0,1-.3-12.7c.26-5.52.16-11.19.06-16.68-.08-4.3-.16-8.76-.07-13.12l-.2-8.78a52.48,52.48,0,0,1,.26-7.27c.47-4,1.25-6.82,2.31-8.34,3.15-4.53,12-4.7,15.7-4.58,2.75.09,11.82.79,13.3,5.61a38.65,38.65,0,0,1,2.07,14.55L76.58,47c.13,5.84.13,12.1,0,19.7,0,2.9,0,5.88.14,8.76.13,4.2.27,8.55,0,12.77l0,.38c-.36,5.53-.67,10.3-7,12.25A32.77,32.77,0,0,1,60.21,102.13Zm-.32-83.24c-4,0-10.82.57-13.34,4.17-.95,1.37-1.69,4.09-2.13,7.88a50.46,50.46,0,0,0-.25,7.12l.2,8.81c-.1,4.37,0,8.81.06,13.1.1,5.51.21,11.2-.06,16.75a64.32,64.32,0,0,0,.29,12.5l.18,1.3C45.5,95.21,46,98.6,51.12,100a35.53,35.53,0,0,0,18.24-.11c5.68-1.74,6-6,6.31-11.33l0-.39c.27-4.17.14-8.49,0-12.66-.09-2.9-.18-5.89-.14-8.81.12-7.6.11-13.85,0-19.68v0l.2-8.94a37.49,37.49,0,0,0-2-14.2c-1-3.34-7.3-4.73-12.36-4.91C61,18.9,60.45,18.89,59.89,18.89Z" fill="#02eafd" />
    <path d="M59.67,100.45a36.65,36.65,0,0,1-11.28-1.7l.31-1c10.83,3.49,21.2.43,22.4,0l0,0c2.4-.65,2.81-7,2.77-9.41,0,0,.13-3.3.17-4,0-.46,0-3.23,0-5.25,0-1.16,0-2.13,0-2.38s0-.78,0-1.21,0-.86,0-1.21c0-.72,0-2,.07-2.67s.07-2.79.08-3.49c0-.5,0-1.34,0-2H68.62v-1h5.73c0-.47,0-1,0-1.27s0-1.38,0-1.9a7.61,7.61,0,0,1-1.06.51c-.46.2-1.15.4-1.34.46,0,0-3.12.61-3.49.12l.4-.31.06-.5c.24,0,2.48-.23,2.75-.3s.83-.25,1.22-.41a5.81,5.81,0,0,0,.93-.45l.55-.35c0-.15,0-.29,0-.4,0-.41,0-2.11,0-3,0-.44,0-1.22,0-2s0-1.64,0-2.1c0-.77,0-1.71,0-2.38l0-1.13c0-.29,0-.54,0-.77,0-.46,0-2.7,0-3l1,0c0,.52-.06,2.57,0,3,0,.23,0,.49,0,.79v1.12c0,.68,0,1.63,0,2.42v2.08c0,.79,0,1.57,0,2,0,.9,0,2.59,0,3,0,.19,0,.44,0,.7s0,.57,0,.82c0,.49,0,1.6,0,2s0,1.12,0,1.68,0,1.94,0,2.64-.07,2.79-.09,3.51,0,1.94-.06,2.65c0,.36,0,.8,0,1.24s0,.84,0,1.18,0,1.21,0,2.37c0,2.14,0,4.8,0,5.29,0,.74-.17,4-.17,4,0,.92.07,9.13-3.35,10.33l0,.05A40.74,40.74,0,0,1,59.67,100.45ZM51,66.15H45v-1h6Z" fill="#02eafd" />
    <path d="M68.46,77.38l0-1,.63,0c.92,0,3.36-.12,3.59-.14L74,76l.57-.06.08,1L74.1,77l-1.35.16c-.22,0-1.63.08-3.67.16Z" fill="#02eafd" />
    <path d="M50,63.17A14.2,14.2,0,0,1,48,63c-.23-.07-1.16-.38-1.57-.56a10.6,10.6,0,0,1-1.25-.65c-.29-.19-.53-.37-.53-.37l.6-.82.5.35a9.24,9.24,0,0,0,1.1.55c.32.15,1.19.44,1.44.52a23.22,23.22,0,0,0,2.34.12l0,.48.5.25C51.08,63.1,50.58,63.17,50,63.17Z" fill="#02eafd" />
    <path d="M48.11,98.63C45.61,96.14,45,88.72,45,88.41l0-.66c0-.69-.06-1.7-.07-2s0-1.25-.06-1.8v-.24c0-.23,0-1.95,0-3.67s0-3.48,0-3.71l0-.69c0-.65,0-1.5,0-1.85s0-1.63-.06-2.58l0-1c0-.29,0-1.06,0-1.8l0-1.47c0-.75,0-2.45,0-2.87,0-.17,0-.6,0-1.11,0-.82,0-1.8,0-2.11,0-.5,0-2.53,0-3.26V53.89c0-.39,0-1.19,0-2s0-1.42,0-1.74,0-1,0-1.66,0-1.14,0-1.41c0-.57.08-3.5.08-3.5,0-3.32,4.07-5.36,4.25-5.45A14.38,14.38,0,0,1,51.52,37a18.71,18.71,0,0,1,3.13-.73c.84-.15,2.21-.23,3.44-.3,1,0,3.25,0,3.89,0s2.59.21,3.35.31a19.16,19.16,0,0,1,2.4.51c.46.13,1.86.69,2.2.84s1.08.57,1.51.82l.22.12a8.31,8.31,0,0,1,.93.72l.28.24-.56.86L71.92,40a7.22,7.22,0,0,0-.76-.6l-.22-.13c-.42-.23-1.14-.65-1.43-.78s-1.69-.68-2.05-.78a18.83,18.83,0,0,0-2.25-.49C64.48,37.16,62.6,37,62,37s-2.87,0-3.81,0c-.72,0-2.42.13-3.32.28a18.47,18.47,0,0,0-2.91.68,13.77,13.77,0,0,0-2.54,1.15c-.08,0-3.71,1.88-3.71,4.57,0,0-.09,2.93-.09,3.49,0,.27,0,.84,0,1.43s0,1.3,0,1.64,0,1,0,1.75,0,1.56,0,2v3.68c0,.74,0,2.78,0,3.28,0,.31,0,1.27,0,2.07,0,.51,0,1,0,1.13,0,.41,0,2.1.05,2.76l0,1.57c0,.73,0,1.5,0,1.79l0,1c0,1,0,2.2.06,2.55s0,1.22.05,1.88v.69c0,.22,0,2,0,3.72s0,3.42,0,3.66v.23c0,.54,0,1.41.06,1.77s0,1.33.08,2l0,.63c0,.05.58,7.33,2.81,9.56Z" fill="#02eafd" />
    <polygon points="53.5 22.42 52.47 22.4 52.56 19.02 53.59 19.05 53.5 22.42" fill="#02eafd" />
    <path d="M53.73,37l-1,0,.13-3.11c0-.3,0-1.07.09-1.85s.08-1.48.09-1.87c0-.83.12-2.3.15-2.91,0-.34,0-1.07.08-1.71s0-1,0-1.16c0-.43.06-1.89.06-1.89l1,0s0,1.49-.06,1.93c0,.19,0,.64,0,1.14,0,.65-.05,1.39-.07,1.73,0,.61-.12,2.07-.15,2.89,0,.4-.06,1.15-.1,1.88s-.08,1.54-.09,1.84Z" fill="#02eafd" />
    <path d="M66.21,37.09l.51,0h-.51c0-.35-.14-2.39-.15-2.76s-.08-1.79-.14-3L65.7,26.7c0-.57-.23-4.21-.23-4.21l1-.06s.2,3.65.22,4.23l.22,4.58c.06,1.22.13,2.78.14,3s.13,2.38.15,2.73Z" fill="#02eafd" />
    <rect x="66.33" y="19.04" width="1.02" height="3.37" fill="#02eafd" />
    <path d="M59.7,87.3a30.54,30.54,0,0,1-9.34-1.43l.31-1a31.38,31.38,0,0,0,18.2,0l.34,1A30.55,30.55,0,0,1,59.7,87.3Z" fill="#02eafd" />
    <path d="M59.87,90.09a32.06,32.06,0,0,1-9.81-1.51l.31-1a32.43,32.43,0,0,0,19,0l.35,1A31.43,31.43,0,0,1,59.87,90.09Z" fill="#02eafd" />
    <path d="M59.68,98.88a36.2,36.2,0,0,1-11.12-1.67l.32-1c11.27,3.63,22,.05,22.15,0l.33,1A40.15,40.15,0,0,1,59.68,98.88Z" fill="#02eafd" />
    <path d="M70.71,98.36c-.1-.57-.19-1.37-.28-2.13s-.16-1.42-.24-1.87c-.87-5-.87-5-1.37-6.57,0-.28-2.85-19.65,1.83-38.79a53.13,53.13,0,0,0,1.06-6.39c-10.83-3.33-21.8-.52-23.73,0A53.09,53.09,0,0,0,49,49c4.68,19.14,1.88,38.51,1.85,38.71a65.62,65.62,0,0,0-1.39,6.65c-.08.45-.16,1.15-.24,1.87s-.19,1.56-.28,2.13l-1-.18c.09-.55.18-1.32.27-2.07s.17-1.46.25-1.92a62.92,62.92,0,0,1,1.41-6.72s.44-3.1.68-8c-3.46.46-4.73,4.21-4.79,4.38l-1-.31s1.56-4.67,5.81-5.11c0-.38,0-.76,0-1.16-1,0-2.9,0-3.21-.06s-1.05-.08-1.42-.15-.82-.12-.82-.12l.13-1,.87.13a12.71,12.71,0,0,0,1.3.13c.29,0,2.19,0,3.18.07a106.21,106.21,0,0,0-2.66-27c-1.9-7.8-2-22.56-2-22.7a2.33,2.33,0,0,1,1.09-2.14,3.57,3.57,0,0,1,.45-.28c.34-.18,1.7-.81,2.29-1.06l1.41-.56.75-.3c.43-.17.87-.29.87-.29l.09,0h.09l.65.05a4.63,4.63,0,0,0,.87-.09l.13,0c.38-.05,2-.23,2.87-.29s2.24-.09,3.24-.08,1.79.11,2.31.16l.32,0,2.21.26c.21,0,.4,0,.54,0a2.57,2.57,0,0,0,.5-.05l.13,0,.11,0a6.49,6.49,0,0,1,.72.24l.94.36,1,.4c.3.12,1,.43,1.32.56s1.33.64,1.54.76l.13.07a2.33,2.33,0,0,1,1,2.19c0,.1-.09,15-2,22.77a108,108,0,0,0-2.58,29.21c4.4.31,6,5.08,6,5.13l-1,.31c-.06-.17-1.39-4.08-5-4.41.24,4.9.69,8,.7,8.06.5,1.57.5,1.57,1.38,6.62.09.48.17,1.2.26,1.94s.17,1.52.27,2.07ZM53.1,22.93a6.81,6.81,0,0,0-.67.23l-.77.3L50.27,24c-.59.25-1.92.87-2.2,1a3.19,3.19,0,0,0-.38.23,1.33,1.33,0,0,0-.62,1.23c0,.14,0,7.92.8,15.1,2.4-.66,13.21-3.26,23.95,0,.75-7.21.8-15,.8-15.15a1.35,1.35,0,0,0-.56-1.28c-.24-.13-1.26-.63-1.53-.75s-1-.42-1.28-.54-.57-.23-1-.39l-1-.4-.45-.15a5.09,5.09,0,0,1-.69.05,5.4,5.4,0,0,1-.56,0l-2.16-.25-.34,0c-.5,0-1.41-.13-2.23-.15-1,0-2.27,0-3.15.07s-2.43.24-2.8.29h-.13a8.18,8.18,0,0,1-1.07.1Z" fill="#02eafd" />
    <path d="M70.11,54a35.8,35.8,0,0,0-20.29,0l-.33-1a37,37,0,0,1,20.93,0Z" fill="#02eafd" />
    <path d="M70.11,54a35.8,35.8,0,0,0-20.29,0l-.33-1a37,37,0,0,1,20.93,0Z" fill="#02eafd" />
    <path d="M75.46,46h-1c0-4.37-2-5.56-2.1-5.61l.49-.9C72.94,39.52,75.46,40.94,75.46,46Z" fill="#02eafd" />
  </g>


);

export default (CarRotate);