import React, { Component } from "react";
import { connect } from "react-redux";
import PreferenceSlider from "../modules/PreferenceSlider";
import { TwitterShareButton, TwitterIcon, FacebookShareButton, FacebookIcon } from "react-share";

class Results extends Component {
  render() {
    const { responses } = this.props;

    const ShareButtons = () => (
      <div className="share-bar">
        <TwitterShareButton url="https://www.confused.com/car-insurance/autonomous-cars-simulation" title="Confused.com | The Conscious Car - How should a machine behave when faced with a moral dilemma where lives are at stake? Take this self-driving car experiment and find out.">
          <TwitterIcon size={60} round={false} iconBgStyle={{ fill: "#023987" }} />
        </TwitterShareButton>

        <FacebookShareButton url="https://www.confused.com/car-insurance/autonomous-cars-simulation" quote="Confused.com | The Conscious Car - How should a machine behave when faced with a moral dilemma where lives are at stake? Take this self-driving car experiment and find out.">
          <FacebookIcon size={60} round={false} iconBgStyle={{ fill: "#023987" }} />
        </FacebookShareButton>
      </div>
    );

    return (
      <main role="main" className="siteContent content results">
        <div>
          <h1>Your results</h1>
          <p>In an accident situation, and based on your choices, a self-driving car would:</p>

          <div className="prefWrapper">

            <PreferenceSlider
              negLabel={"In car"}
              posLabel={"Pedestrian"}
              score={responses.inCarVsRoadUser}
              range="6"
              negTitle={"Protect the car’s occupants as a priority"}
              neuTitle={"Value all lives as equal"}
              posTitle={"Protect pedestrians as a priority"}
            />

            <PreferenceSlider
              negLabel={"Young"}
              posLabel={"Old"}
              score={responses.youngVsOld}
              range="4"
              negTitle={"Protect younger road users as a priority"}
              neuTitle={"Not take age into account when protecting road users"}
              posTitle={"Protect older road users as a priority"}
            />

            <PreferenceSlider
              negLabel={"Humans"}
              posLabel={"Dogs"}
              score={responses.humanVsAnimal}
              range="3"
              negTitle={"Protect humans as a priority"}
              neuTitle={"Place equal worth on humans and dogs"}
              posTitle={"Protect dogs as a priority"}
            />

            <PreferenceSlider
              negLabel={"Few"}
              posLabel={"Many"}
              score={responses.fewVsMany}
              range="4"
              negTitle={"Consider individuals rather than numbers"}
              neuTitle={"Neutral"}
              posTitle={"Protect as many lives as possible"}
            />
          </div>

          {/* <ScoresTable responses={responses} /> */}

          <ShareButtons />
        </div>

      </main >
    );
  }
}

const mapStateToProps = state => ({
  responses: state.form.userResponses.values
});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Results);
