import firebase from "firebase/app";
import "firebase/database";

var config = {
  apiKey: "AIzaSyBoXHXhbmbstD8kYbBlZ1XsYoLUICKsxlo",
  authDomain: "confused-driverless.firebaseapp.com",
  databaseURL: "https://confused-driverless.firebaseio.com",
  projectId: "confused-driverless",
  storageBucket: "confused-driverless.appspot.com",
  messagingSenderId: "127467965265"
};

var fire = firebase.initializeApp(config);
export default fire;
