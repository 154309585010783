export const scenarios = [
  {
    description: `A car with <strong>1 adult occupant</strong> is driving towards a barrier on the left-hand side of a crossing. There is <strong>1 adult pedestrian</strong> on the right-hand side of the crossing.`,
    left: {
      occupants: [],
      barrier: true
    },
    right: {
      occupants: ["adult"]
    },
    car: {
      occupants: ["adult"]
    },
    a: {
      description: `The car should continue in the left-hand lane, <strong>1 adult occupant of the car dies</strong>.`,
      harmed: "car",
      inCarVsRoadUser: 1
    },
    b: {
      description: `The car should move to the right-hand lane, <strong>1 adult pedestrian dies</strong>.`,
      harmed: "right",
      inCarVsRoadUser: -1
    }
  },
  {
    description: `A car with <strong>1 adult occupant</strong> is driving towards a barrier on the left-hand side of a crossing. There is <strong>1 child pedestrian</strong> on the right-hand side of the crossing.`,
    left: {
      occupants: [],
      barrier: true
    },
    right: {
      occupants: ["child"]
    },
    car: {
      occupants: ["adult"]
    },
    a: {
      description: `The car should continue in the left-hand lane, <strong>1 adult occupant of the car dies</strong>.`,
      harmed: "car",
      inCarVsRoadUser: 1,
      youngVsOld: -1
    },
    b: {
      description: `The car should move to the right-hand lane, <strong>1 child pedestrian dies</strong>.`,
      harmed: "right",
      inCarVsRoadUser: -1,
      youngVsOld: 1
    }
  },
  {
    description: `A car with <strong>1 adult occupant</strong> is driving towards a barrier on the left-hand side of a crossing. There is <strong>1 elderly pedestrian</strong> on the right-hand side of the crossing.`,
    left: {
      occupants: [],
      barrier: true
    },
    right: {
      occupants: ["elder"]
    },
    car: {
      occupants: ["adult"]
    },
    a: {
      description: `The car should continue in the left-hand lane, <strong>1 adult occupant of the car dies</strong>.`,
      harmed: "car",
      inCarVsRoadUser: 1,
      youngVsOld: 1
    },
    b: {
      description: `The car should move to the right-hand lane, <strong>1 elderly pedestrian dies</strong>.`,
      harmed: "right",
      inCarVsRoadUser: -1,
      youngVsOld: -1
    }
  },
  {
    description: `A car with no occupants is driving towards <strong>1 child pedestrian</strong> on the left-hand side of a crossing. There is <strong>1 elderly pedestrian</strong> on the right-hand side of the crossing.`,
    left: {
      occupants: ["child"]
    },
    right: {
      occupants: ["elder"]
    },
    car: {
      occupants: []
    },
    a: {
      description: `The car should continue in the left-hand lane, <strong>1 child pedestrian dies</strong>.`,
      harmed: "left",
      youngVsOld: 1
    },
    b: {
      description: `The car should move to the right-hand lane, <strong>1 elderly pedestrian dies</strong>.`,
      harmed: "right",
      youngVsOld: -1
    }
  },
  {
    description: `A car with <strong>1 adult occupant</strong> is driving towards <strong>1 dog</strong> on the left-hand side of a crossing. There is a barrier on the right-hand side of the road.`,
    left: {
      occupants: ["dog"]
    },
    right: {
      occupants: [],
      barrier: true
    },
    car: {
      occupants: ["adult"]
    },
    a: {
      description: `The car should continue in the left-hand lane, <strong>1 dog dies</strong>.`,
      harmed: "left",
      inCarVsRoadUser: -1,
      humanVsAnimal: -1
    },
    b: {
      description: `The car should move to the right-hand lane, <strong>1 adult occupant of the car dies</strong>.`,
      harmed: "car",
      inCarVsRoadUser: 1,
      humanVsAnimal: 1
    }
  },
  {
    description: `A car with no occupants is driving towards <strong>1 adult pedestrian</strong> on the left-hand side of a crossing. There is <strong>1 dog</strong> on the right-hand side of the crossing.`,
    left: {
      occupants: ["adult"]
    },
    right: {
      occupants: ["dog"]
    },
    car: {
      occupants: []
    },
    a: {
      description: `The car should continue in the left-hand lane, <strong>1 adult pedestrian dies</strong>.`,
      harmed: "left",
      humanVsAnimal: 1
    },
    b: {
      description: `The car should move to the right-hand lane, <strong>1 dog dies</strong>.`,
      harmed: "right",
      humanVsAnimal: -1
    }
  },
  {
    description: `A car with <strong>1 adult occupant</strong> is driving towards a barrier on the left-hand side of a crossing. There are <strong>2 adult pedestrians</strong> on the right-hand side of the crossing.`,
    left: {
      occupants: [],
      barrier: true
    },
    right: {
      occupants: ["adult", "adult"]
    },
    car: {
      occupants: ["adult"]
    },
    a: {
      description: `The car should continue in the left-hand lane, <strong>1 adult occupant of the car dies</strong>.`,
      harmed: "car",
      inCarVsRoadUser: 1,
      fewVsMany: 1
    },
    b: {
      description: `The car should move to the right-hand lane, <strong>2 adult pedestrians die</strong>.`,
      harmed: "right",
      inCarVsRoadUser: -1,
      fewVsMany: -1
    }
  },
  {
    description: `A car with <strong>2 adult occupants</strong> is driving towards <strong>1 adult pedestrian</strong> on the left-hand side of a crossing. There is a barrier on the right-hand side of the crossing.`,
    left: {
      occupants: ["adult"]
    },
    right: {
      occupants: [],
      barrier: true
    },
    car: {
      occupants: ["adult", "adult"]
    },
    a: {
      description: `The car should continue in the left-hand lane, <strong>1 adult pedestrian dies</strong>.`,
      harmed: "left",
      inCarVsRoadUser: -1,
      fewVsMany: 1
    },
    b: {
      description: `The car should move to the right-hand lane, <Strong>2 adult occupants of the car die</strong>.`,
      harmed: "car",
      inCarVsRoadUser: 1,
      fewVsMany: -1
    }
  },
  {
    description: `A car with no occupants is driving towards <strong>1 adult pedestrian</strong> on the left-hand side of a crossing. There are <strong>3 dogs</strong> on the right-hand side of the crossing.`,
    left: {
      occupants: ["adult"]
    },
    right: {
      occupants: ["dog", "dog", "dog"]
    },
    car: {
      occupants: []
    },
    a: {
      description: `The car should continue in the left-hand lane, <strong>1 adult pedestrians dies</strong>.`,
      harmed: "left",
      humanVsAnimal: 1,
      fewVsMany: 1
    },
    b: {
      description: `The car should move to the right-hand lane, <strong>3 dogs die</strong>.`,
      harmed: "right",
      humanVsAnimal: -1,
      fewVsMany: -1
    }
  },
  {
    description: `A car with no occupants is driving towards <strong>3 elderly pedestrians</strong> on the left-hand side of a crossing. There is <strong>1 child pedestrian</strong> on the right-hand side of the crossing.`,
    left: {
      occupants: ["elder", "elder", "elder"]
    },
    right: {
      occupants: ["child"]
    },
    car: {
      occupants: []
    },
    a: {
      description: `The car should continue in the left-hand lane, <strong>3 elderly pedestrians die</strong>.`,
      harmed: "left",
      youngVsOld: -1,
      fewVsMany: -1
    },
    b: {
      description: `The car should move to the right-hand lane, <strong>1 child pedestrian dies</strong>.`,
      harmed: "right",
      youngVsOld: 1,
      fewVsMany: 1
    }
  }
];
