import React from "react";

const AdultIcon = () => (

  <g>
    <path d="M10.34,7.48h-1l-.21,0L9,7.48H8A1.83,1.83,0,0,0,6.16,9.31v2.46a2,2,0,0,0,1.06,1.54v4.2A.88.88,0,0,0,8,18.4a.86.86,0,0,0,.95-.86V14a.23.23,0,0,1,.23-.23h0A.24.24,0,0,1,9.4,14v3.49a.87.87,0,0,0,.77.89.86.86,0,0,0,.94-.86V12.91a2,2,0,0,0,1.06-1.55v-2A1.83,1.83,0,0,0,10.34,7.48Z" fill="#fff" />
    <path d="M10.25,18.62h-.1a1.11,1.11,0,0,1-1-1.11v0A1.08,1.08,0,0,1,8,18.62a1.11,1.11,0,0,1-1-1.11V13.43a2.2,2.2,0,0,1-1.06-1.66V9.31A2.05,2.05,0,0,1,8,7.26h2.35A2.05,2.05,0,0,1,12.4,9.31v2A2.23,2.23,0,0,1,11.34,13v4.51a1.09,1.09,0,0,1-1.09,1.08ZM9.17,13.57a.45.45,0,0,1,.45.45v3.49a.66.66,0,0,0,.57.67.63.63,0,0,0,.49-.17.64.64,0,0,0,.21-.47V9.59h.45v2.88A1.6,1.6,0,0,0,12,11.36v-2A1.61,1.61,0,0,0,10.34,7.7H9.28a.81.81,0,0,0-.22,0H8A1.61,1.61,0,0,0,6.38,9.31v2.46A1.56,1.56,0,0,0,7,12.87V9.58h.44v4.13h0v3.8a.66.66,0,0,0,.58.67A.64.64,0,0,0,8.51,18a.63.63,0,0,0,.2-.47V14A.46.46,0,0,1,9.17,13.57Z" fill="#000b25" />
    <circle cx="9.11" cy="5.19" r="1.7" fill="#fff" />
    <path d="M9.11,7.12A1.93,1.93,0,1,1,11,5.19,1.93,1.93,0,0,1,9.11,7.12Zm0-3.41a1.48,1.48,0,1,0,1.48,1.48A1.48,1.48,0,0,0,9.11,3.71Z" />
    <path d="M10.4,7.39H8a2,2,0,0,0-2,2v2.16a2.25,2.25,0,0,0,1,1.61v4.3a1,1,0,0,0,2.07,0V13.62h.16v3.87a1,1,0,0,0,2.06,0v-4.2a2.28,2.28,0,0,0,1.16-1.71V9.42A2,2,0,0,0,10.4,7.39Z" fill="#fff" />
    <path d="M10.25,18.74a1.25,1.25,0,0,1-1.11-.67,1.26,1.26,0,0,1-2.37-.58V13.3a2.43,2.43,0,0,1-1-1.72V9.42A2.26,2.26,0,0,1,8,7.16H10.4a2.27,2.27,0,0,1,2.26,2.26v2.16a2.5,2.5,0,0,1-1.15,1.83v4.08A1.26,1.26,0,0,1,10.25,18.74ZM9.06,13.4h.16a.22.22,0,0,1,.22.22v3.87a.81.81,0,1,0,1.62,0v-4.2a.23.23,0,0,1,.1-.19,2.06,2.06,0,0,0,1.05-1.52V9.42A1.81,1.81,0,0,0,10.4,7.61H8A1.81,1.81,0,0,0,6.2,9.42v2.16A2.09,2.09,0,0,0,7.13,13a.25.25,0,0,1,.08.18v4.3a.82.82,0,0,0,1.63,0V13.62A.22.22,0,0,1,9.06,13.4Z" />
    <path d="M7,13.4a.22.22,0,0,1-.22-.22V9.84a.22.22,0,1,1,.44,0v3.34A.22.22,0,0,1,7,13.4Z" />
    <path d="M11.27,13.4a.22.22,0,0,1-.23-.22V9.84a.23.23,0,1,1,.45,0v3.34A.22.22,0,0,1,11.27,13.4Z" />
  </g>


);

export default (AdultIcon);