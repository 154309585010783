import React, { Component } from "react";
import Occupant from "./Occupant"

class CrossingSide extends Component {
  render() {
    const inDanger = this.props.inDanger;
    const barrier = this.props.barrier;
    const occupants = this.props.occupants;
    const x = this.props.x;
    const y = this.props.y;
    const lane = this.props.lane;
    return (
      <svg id="crossingSVG" className={lane} viewBox="0 0 120 120" x={x} y={y}>

        <defs>
          <pattern id="crossing" width="15" height="30" patternUnits="userSpaceOnUse">
            <rect x="0" y="0" width="10" height="30" fill="white" />
          </pattern>

          <pattern id="hazardStripes" width="4" height="4" patternTransform="rotate(45 0 0)" patternUnits="userSpaceOnUse">
            <rect fill="orange" width="4" height="4" />
            <line x1="0" y1="0" x2="0" y2="4" stroke="black" strokeWidth="4" />
          </pattern>
        </defs>

        <g className="crossing-animate">

          <rect width="40" height="30" y="10" fill="url(#crossing)" />
          {barrier && <g className="barrier-animate"><rect width="34" height="8" x="3" y="36" fill="url(#hazardStripes)" stroke="black" strokeWidth="1" /></g>}

          {occupants[0] && (<Occupant type={occupants[0]} x="0" y="0" inDanger={inDanger} />)}
          {occupants[2] && (<Occupant type={occupants[2]} x="0" y="20" inDanger={inDanger} />)}
          {occupants[1] && (<Occupant type={occupants[1]} x="20" y="20" inDanger={inDanger} />)}
          {occupants[3] && (<Occupant type={occupants[3]} x="20" y="0" inDanger={inDanger} />)}

        </g>


      </svg >
    );
  }
}

export default CrossingSide;
