export default (state = { page: 1, scenario: 1 }, action) => {
  switch (action.type) {
    case "NEXT_SCENARIO":
      return {
        ...state,
        scenario: state.scenario + 1
      };
    case "NEXT_PAGE":
      return {
        ...state,
        page: state.page + 1
      };
    default:
      return state;
  }
};
