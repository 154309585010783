import React, { Component } from "react";
import Occupant from "./Occupant"

class Car extends Component {
  render() {
    const inDanger = this.props.inDanger;
    const occupants = this.props.occupants;
    const x = this.props.x;
    const y = this.props.y;
    return (
      <svg id="carSVG" viewBox="0 0 120 120" x={x} y={y}>
        <title>Car</title>
        <g className="car-animate">
          <path d="M34.85,87.3c.07,3.21.06,6.42,0,9.63,0,3.48.29,7,.06,10.5-.18,2.74-.26,5-3.27,5.95a17.68,17.68,0,0,1-9,.06c-2.93-.78-3-2.91-3.35-5.46a32.45,32.45,0,0,1-.14-6.16c.24-4.82-.11-9.72,0-14.57L19,83a24.38,24.38,0,0,1,.13-3.52,10.33,10.33,0,0,1,1.08-4c1.39-2,5.29-2.21,7.46-2.13,1.77.06,5.64.53,6.27,2.56a18.88,18.88,0,0,1,1,7Z" fill="#00112b" />
          <path d="M27.1,114.25a18.05,18.05,0,0,1-4.57-.57c-2.84-.75-3.11-2.74-3.43-5L19,108a32.59,32.59,0,0,1-.15-6.21c.13-2.69.08-5.47,0-8.15,0-2.1-.08-4.28,0-6.41L18.76,83a25.75,25.75,0,0,1,.13-3.55A9.57,9.57,0,0,1,20,75.33c1.54-2.21,5.88-2.3,7.67-2.24,1.34,0,5.77.38,6.5,2.74a18.81,18.81,0,0,1,1,7.11l-.1,4.36c.06,2.85.07,5.91,0,9.63,0,1.42,0,2.87.07,4.28.06,2.05.13,4.18,0,6.24v.19c-.18,2.7-.33,5-3.44,6A16,16,0,0,1,27.1,114.25ZM27,73.58c-2,0-5.29.27-6.52,2a9.22,9.22,0,0,0-1,3.85,24.77,24.77,0,0,0-.13,3.48l.1,4.31c-.05,2.13,0,4.3,0,6.4.05,2.69.1,5.47,0,8.19a32.21,32.21,0,0,0,.14,6.1l.09.64c.32,2.29.55,4,3.07,4.62a17.34,17.34,0,0,0,8.91,0c2.78-.86,2.92-2.92,3.09-5.54v-.19c.14-2,.07-4.15,0-6.19,0-1.42-.09-2.88-.07-4.31.06-3.71,0-6.77,0-9.61h0l.1-4.37a18.35,18.35,0,0,0-1-6.94c-.51-1.64-3.57-2.31-6-2.4Z" fill="#02eafd" />
          <path d="M26.84,113.43a17.85,17.85,0,0,1-5.52-.83l.16-.47a19.42,19.42,0,0,0,10.94,0v0c1.17-.32,1.37-3.43,1.35-4.6,0,0,.06-1.61.08-2s0-1.58,0-2.56v-1.17c0-.17,0-.38,0-.59s0-.42,0-.59c0-.35,0-1,0-1.31l0-1.7c0-.25,0-.65,0-1h-2.8v-.5H34c0-.23,0-.47,0-.62s0-.68,0-.93c-.14.08-.3.15-.52.25s-.56.19-.66.22-1.52.3-1.7.06l.2-.15,0-.25c.12,0,1.22-.11,1.35-.14s.4-.12.59-.2a4.14,4.14,0,0,0,.46-.22l.27-.18v-.19c0-.2,0-1,0-1.47v-1c0-.4,0-.8,0-1,0-.38,0-.84,0-1.17v-.55c0-.14,0-.26,0-.38s0-1.32,0-1.47h.5c0,.25,0,1.25,0,1.45s0,.24,0,.39v.54c0,.34,0,.8,0,1.19,0,.22,0,.62,0,1v1c0,.44,0,1.26,0,1.46s0,.22,0,.34v.4c0,.24,0,.78,0,1s0,.54,0,.82,0,1,0,1.29l0,1.71c0,.35,0,1,0,1.3,0,.17,0,.39,0,.6s0,.41,0,.58V103c0,1,0,2.35,0,2.58s-.08,2-.08,2c0,.44,0,4.46-1.64,5v0A20.3,20.3,0,0,1,26.84,113.43ZM22.59,96.67H19.67v-.5h2.92Z" fill="#02eafd" />
          <path d="M31.13,102.16l0-.5h.31l1.75-.07.66-.08.28,0,0,.5-.26,0-.66.08-1.79.08Z" fill="#02eafd" />
          <path d="M22.12,95.21a7.11,7.11,0,0,1-1-.09c-.11,0-.57-.18-.77-.27s-.48-.23-.61-.32l-.26-.18.3-.4.24.17a5.33,5.33,0,0,0,.54.27c.15.07.58.21.7.25a11.22,11.22,0,0,0,1.15.06l0,.24.24.12C22.64,95.18,22.4,95.21,22.12,95.21Z" fill="#02eafd" />
          <path d="M21.19,112.54c-1.22-1.22-1.51-4.84-1.52-5v-.32c0-.34,0-.84,0-1s0-.61,0-.88v-.12c0-.11,0-1,0-1.79s0-1.7,0-1.81v-.34c0-.32,0-.74,0-.91s0-.79,0-1.26v-.49c0-.15,0-.53,0-.89v-.71c0-.37,0-1.2,0-1.41s0-.29,0-.54,0-.88,0-1,0-1.24,0-1.59V89.72c0-.35,0-.69,0-.85s0-.49,0-.81,0-.56,0-.69c0-.28,0-1.71,0-1.71,0-1.62,2-2.62,2.07-2.66a6.23,6.23,0,0,1,1.3-.59,7.92,7.92,0,0,1,1.53-.36,16.61,16.61,0,0,1,1.68-.15c.48,0,1.59,0,1.9,0s1.26.11,1.64.15a9.48,9.48,0,0,1,1.17.25c.22.06.91.34,1.07.41s.53.28.74.41l.11,0a3.54,3.54,0,0,1,.45.35l.14.12-.27.42-.19-.15a2.72,2.72,0,0,0-.38-.3l-.11-.06c-.2-.12-.55-.32-.69-.38s-.83-.34-1-.39-.77-.19-1.1-.23-1.28-.14-1.6-.15-1.4,0-1.86,0-1.18.06-1.62.14a8.68,8.68,0,0,0-1.42.33,6.28,6.28,0,0,0-1.24.56S20,84.35,20,85.67c0,0,0,1.43,0,1.7s0,.41,0,.7,0,.63,0,.8,0,.5,0,.85v2.76c0,.36,0,1.36,0,1.6s0,.62,0,1,0,.47,0,.55,0,1,0,1.35v.77c0,.36,0,.73,0,.87v.5c0,.47,0,1.08,0,1.25s0,.6,0,.92v.33c0,.11,0,1,0,1.82s0,1.68,0,1.79v.12c0,.26,0,.68,0,.86s0,.65,0,1v.31s.28,3.58,1.37,4.67Z" fill="#02eafd" />
          <polygon points="23.82 75.3 23.32 75.29 23.36 73.64 23.86 73.65 23.82 75.3" fill="#02eafd" />
          <path d="M23.94,82.42l-.5,0,.06-1.52c0-.15,0-.52,0-.9l.05-.92c0-.4.06-1.12.07-1.42s0-.52,0-.84,0-.47,0-.56,0-.93,0-.93l.5,0s0,.72,0,.94,0,.31,0,.55,0,.68,0,.85-.05,1-.07,1.41c0,.2,0,.56-.05.92s0,.75,0,.9Z" fill="#02eafd" />
          <path d="M30,82.47l.25,0H30c0-.17-.06-1.16-.07-1.34s0-.88-.07-1.48l-.11-2.24c0-.28-.1-2.06-.1-2.06l.49,0s.1,1.78.11,2.06l.11,2.24c0,.6.07,1.36.07,1.48s.06,1.16.07,1.33Z" fill="#02eafd" />
          <rect x="30.1" y="73.65" width="0.5" height="1.65" fill="#02eafd" />
          <path d="M26.85,107a15,15,0,0,1-4.56-.7l.15-.48a15.42,15.42,0,0,0,8.9,0l.16.47A15,15,0,0,1,26.85,107Z" fill="#02eafd" />
          <path d="M26.94,108.37a15.79,15.79,0,0,1-4.8-.74l.16-.48a15.74,15.74,0,0,0,9.3,0l.17.47A15,15,0,0,1,26.94,108.37Z" fill="#02eafd" />
          <path d="M26.84,112.67a17.83,17.83,0,0,1-5.43-.82l.15-.48a19.1,19.1,0,0,0,10.83,0l.16.48A19.82,19.82,0,0,1,26.84,112.67Z" fill="#02eafd" />
          <path d="M32.23,112.41c0-.28-.09-.67-.13-1s-.08-.69-.12-.91a19.17,19.17,0,0,0-.67-3.21,55.83,55.83,0,0,1,.89-19,25.62,25.62,0,0,0,.52-3.12,22.12,22.12,0,0,0-11.6,0,27.91,27.91,0,0,0,.52,3.11,56.16,56.16,0,0,1,.91,18.92,30.86,30.86,0,0,0-.68,3.25c0,.22-.08.56-.12.91s-.09.76-.14,1l-.49-.08c.05-.27.09-.65.13-1s.09-.71.13-.94a29.12,29.12,0,0,1,.68-3.28s.22-1.51.34-3.89a3.19,3.19,0,0,0-2.34,2.15l-.48-.16s.77-2.28,2.84-2.49c0-.19,0-.38,0-.57l-1.57,0c-.2,0-.51,0-.69-.07l-.4-.06.06-.5s.25,0,.43.07l.63.06,1.55,0a51.91,51.91,0,0,0-1.29-13.22c-.94-3.81-1-11-1-11.09a1.15,1.15,0,0,1,.53-1l.23-.14,1.11-.51.69-.28.37-.14a3.76,3.76,0,0,1,.43-.15h.08l.32,0a2.72,2.72,0,0,0,.43,0h.06c.19,0,1-.11,1.4-.14s1.09,0,1.59,0,.87,0,1.13.08h.15l1.08.12a1.28,1.28,0,0,0,.27,0l.24,0h.12l.35.12.46.18.49.19c.15.06.51.21.64.28l.76.37.06,0a1.13,1.13,0,0,1,.49,1.07s0,7.32-1,11.13a52.67,52.67,0,0,0-1.26,14.28c2.15.15,2.93,2.48,2.94,2.5l-.48.16a3.13,3.13,0,0,0-2.44-2.16c.12,2.39.34,3.9.34,3.94a17.54,17.54,0,0,1,.68,3.24c0,.23.08.58.12.94s.09.75.14,1Zm-8.6-36.86-.33.11-.37.15-.68.27c-.29.12-.94.43-1.08.5l-.19.11a.66.66,0,0,0-.3.6c0,.07,0,3.87.39,7.38a22.79,22.79,0,0,1,11.71,0c.36-3.53.39-7.35.39-7.41a.65.65,0,0,0-.28-.62l-.74-.37L31.52,76,31,75.81l-.49-.2-.22-.07-.34,0h-.27l-1.06-.12-.16,0c-.25,0-.69-.06-1.1-.07s-1.1,0-1.53,0-1.19.12-1.37.14h-.07a4.8,4.8,0,0,1-.52,0Z" fill="#02eafd" />
          <path d="M31.94,90.72a17.56,17.56,0,0,0-9.91,0l-.17-.47a18.12,18.12,0,0,1,10.23,0Z" fill="#02eafd" />
          <path d="M31.94,90.72a17.56,17.56,0,0,0-9.91,0l-.17-.47a18.12,18.12,0,0,1,10.23,0Z" fill="#02eafd" />
          <path d="M34.56,86.81h-.5c0-2.14-1-2.72-1-2.74l.24-.44S34.56,84.35,34.56,86.81Z" fill="#02eafd" />
          {occupants[0] && (<Occupant type={occupants[0]} x="32" y="80" inDanger={inDanger} />)}
          {occupants[2] && (<Occupant type={occupants[2]} x="32" y="100" inDanger={inDanger} />)}
          {occupants[1] && (<Occupant type={occupants[1]} x="2" y="80" inDanger={inDanger} />)}
          {occupants[3] && (<Occupant type={occupants[3]} x="2" y="100" inDanger={inDanger} />)}
        </g>
      </svg >
    );
  }
}

export default Car;
