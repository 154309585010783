import React from "react";

const ChildIcon = () => (
  <g>
    <path d="M12.34,12.3l-1.43-1.43-.15-.11a.39.39,0,0,0-.26-.09H7.8a.68.68,0,0,0-.52.19L5.84,12.3a.68.68,0,1,0,1,1l.69-.7v.5a.4.4,0,0,0,.41.4h2.6a.4.4,0,0,0,.4-.4v-.28l.49.48a.68.68,0,0,0,.95,0A.69.69,0,0,0,12.34,12.3Z" fill="#fff" />
    <path d="M10.5,13.69H7.9a.63.63,0,0,1-.63-.59L7,13.42a.94.94,0,0,1-.64.26.92.92,0,0,1-.63-.26.9.9,0,0,1,0-1.27l1.43-1.44a1,1,0,0,1,.66-.26H10.5a.59.59,0,0,1,.38.14.83.83,0,0,1,.18.13l1.44,1.44a.9.9,0,0,1-1.27,1.27l-.14-.14A.63.63,0,0,1,10.5,13.69ZM7.71,12v1a.19.19,0,0,0,.19.18h2.6a.18.18,0,0,0,.18-.18v-.82l.86.86a.45.45,0,1,0,.64-.64L10.75,11l-.1-.07,0,0a.16.16,0,0,0-.11,0H7.79a.45.45,0,0,0-.35.13L6,12.46a.45.45,0,1,0,.64.64Z" />
    <rect x="5.83" y="15.09" width="4.21" height="1.5" rx="0.75" ry="0.75" transform="translate(-9.66 17.88) rotate(-70)" fill="#fff" />
    <path d="M7.47,18.09A1,1,0,0,1,7.14,18h0a1,1,0,0,1-.58-1.25l.93-2.55a1,1,0,1,1,1.82.67l-.92,2.55a1,1,0,0,1-.51.54A1,1,0,0,1,7.47,18.09ZM8.4,14a.53.53,0,0,0-.49.34L7,16.93a.53.53,0,0,0,.31.68h0a.53.53,0,0,0,.41,0,.57.57,0,0,0,.27-.3l.93-2.55a.53.53,0,0,0-.32-.67Z" />
    <rect x="9.11" y="13.73" width="1.5" height="4.21" rx="0.75" ry="0.75" fill="#fff" />
    <path d="M9.86,18.17a1,1,0,0,1-1-1V14.48a1,1,0,1,1,1.94,0V17.2A1,1,0,0,1,9.86,18.17Zm0-4.21a.52.52,0,0,0-.52.52V17.2a.52.52,0,0,0,.52.52.52.52,0,0,0,.53-.52V14.48A.52.52,0,0,0,9.86,14Z" />
    <path d="M11,5.42l-1.94.26H9A2.29,2.29,0,1,0,11.38,8a2.22,2.22,0,0,0-.22-1l.43-.4A.69.69,0,0,0,11,5.42Z" fill="#fff" />
    <path d="M9.09,10.48a2.51,2.51,0,0,1-.06-5h.05L11,5.2a.91.91,0,0,1,.74,1.58l-.31.28A2.58,2.58,0,0,1,11.6,8,2.51,2.51,0,0,1,9.09,10.48Zm2-4.84-2,.27A2.08,2.08,0,1,0,11,7.1L10.89,7l.55-.5a.45.45,0,0,0,.11-.54.47.47,0,0,0-.49-.27Z" />
    <line x1="11.16" y1="7.01" x2="6.86" y2="8.58" fill="#fff" />
    <rect x="6.72" y="7.57" width="4.58" height="0.45" transform="translate(-2.13 3.56) rotate(-20.05)" />
    <path d="M10.31,15,9.23,16.3a.2.2,0,0,1-.18-.18l0-.81-.41.79a.19.19,0,0,1-.25.11l-1-.47a.45.45,0,0,1-.26-.57l.26-.71a.13.13,0,0,0,0-.06v-.73a.44.44,0,0,1,.44-.44h2.56a.44.44,0,0,1,.44.44v2a.64.64,0,0,1-.64.64Z" fill="#fff" />
    <path d="M9.51,16.52H9.22a.42.42,0,0,1-.39-.38l0,.05a.47.47,0,0,1-.2.22.44.44,0,0,1-.32,0l-1-.48a.66.66,0,0,1-.38-.84l.26-.71v-.72A.67.67,0,0,1,7.79,13h2.56a.67.67,0,0,1,.66.67v2a.87.87,0,0,1-.85.87Zm-.24-.44h.88a.42.42,0,0,0,.42-.42v-2a.22.22,0,0,0-.22-.22H7.79a.21.21,0,0,0-.21.22v.73a.35.35,0,0,1,0,.14l-.26.71a.22.22,0,0,0,.13.28l1,.46.41-.78a.22.22,0,0,1,.24-.12.25.25,0,0,1,.18.21Z" />
  </g>

);

export default (ChildIcon);