import React from "react";

const CentralReservation = () => (

  <svg id="centralReservationSVG" viewBox="0 0 120 120" y="5">
    <line className="roadMarking" x1="60" x2="60" y1="-125" y2="235" stroke="white" strokeDasharray="20" />
    <path d="M60,38.12a5.46,5.46,0,0,1-5.46-5.44V6.49a5.46,5.46,0,1,1,10.92,0V32.68A5.46,5.46,0,0,1,60,38.12Z" fill="#000f31" />
    <path d="M60,1.58a4.91,4.91,0,0,1,4.91,4.91h0V32.68a4.91,4.91,0,0,1-9.82,0V6.49A4.91,4.91,0,0,1,60,1.58M60,.49a6,6,0,0,0-6,6V32.68a6,6,0,1,0,12,0V6.49A6,6,0,0,0,60,.49Z" fill="#fff" />
  </svg>

);

export default (CentralReservation);