import React, { Component } from "react";
import CarRotate from "./../assets/CarRotate";

class TrackSVG extends Component {
  render() {
    const trackMoving = this.props.trackMoving
    const trackRotate = this.props.trackRotate
    const radialMask = this.props.radialMask
    const opacity = this.props.opacity
    const transformAngle = this.props.transformAngle
    return (

      <div id="trackAnimated-wrapper">
        <svg viewBox="0 0 120 120" id="trackSVG" style={{ opacity: `${opacity}`, transform: `rotateX(${transformAngle}deg` }} className={trackRotate} >

          <defs>

            {radialMask &&
              <clipPath id="radialMask">
                <circle cx="60" cy="60" r="56" fill="black" />
              </clipPath>
            }

            <pattern id="track" width="5" height="5" patternUnits="userSpaceOnUse">
              <line opacity="0.5" className={trackMoving} x1="0" y1="0" x2="5" y2="0" width="5" stroke="#0074FF" strokeWidth="0.1" />
              <line opacity="0.5" className="vertLine" x1="0" y1="0" x2="0" y2="5" width="5" stroke="#0074FF" strokeWidth="0.2" />
            </pattern>
          </defs>

          <rect width="100%" height="100%" fill="url(#track)" clipPath="url(#radialMask)" />

          {trackRotate === "rotate active" &&
            <g>
              <circle className="ring" cx="60" cy="60" r="56" strokeDasharray="1000" strokeDashoffset="1000" fill="transparent" />
              <CarRotate />
            </g>
          }
        </svg>
      </div >

    );
  }
}

export default (TrackSVG);