import React, { Component } from "react";

class PreferenceSlider extends Component {
  render() {
    const range = this.props.range;
    const negLabel = this.props.negLabel;
    const posLabel = this.props.posLabel;
    const score = this.props.score;
    const percentage = score !== 0 ? (score / range) * 100 : 0;

    const negTitle = this.props.negTitle;
    const neuTitle = this.props.neuTitle;
    const posTitle = this.props.posTitle;
    let prefTitle = this.props.prefTitle;

    prefTitle = () => {
      if (percentage > 0) return posTitle;

      if (percentage === 0) return neuTitle;

      if (percentage < 0) return negTitle;
    };

    return (
      <aside className="prefCard">
        <div className="prefCard-content">
          <h3 className="prefCard-title">{prefTitle()}</h3>
          <figure className="prefSlider">
            <svg className="prefSlider-svg" viewBox="-104 0 208 20">
              <path
                d="M-100 0, 0 20.1, 100 0, 100 20, -100 20, -100 0"
                fill="#1cf3a0"
              />
              <path
                strokeWidth="8"
                stroke="white"
                d={`M ${percentage} 0, ${percentage} 20`}
              />
            </svg>
            <figcaption className="prefLabels">
              <strong className="prefLabel-neg">{negLabel}</strong>
              <strong className="prefLabel-pos">{posLabel}</strong>
            </figcaption>
          </figure>
        </div>
      </aside>
    );
  }
}

export default PreferenceSlider;
