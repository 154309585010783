import React, { Component } from "react";
import CrossingSide from "./CrossingSide";
import Car from "./Car";
import CentralReservation from "./../assets/CentralReservation";

class SceneSVG extends Component {
  render() {
    const {
      chosenOutcome,
      scenario,
      selectOption,
      scenarioNumber
    } = this.props;
    const outcome = chosenOutcome;
    const inDanger = scenario[chosenOutcome].harmed;
    return (
      <figure id="sceneSVG-wrapper">
        <svg
          aria-labelledby="#scene-description"
          className="sceneSVG"
          viewBox="0 0 120 120"
        >

          <g className="arrows-animate">
            <g id="optionA"
              opacity={outcome === "a" ? 1 : 0.2}>
              <path d="M36.36,56.64l-6.27-6.27-6.27,6.27h5v2.69a4,4,0,0,0,0,7.53V93h2.6V66.86a4,4,0,0,0,0-7.53V56.64Z" fill="#fff" />
              <path d="M31.86,65.06h-.65A.26.26,0,0,1,31,65a.32.32,0,0,1-.1-.13l-.22-.63H29.33l-.22.63A.31.31,0,0,1,29,65a.29.29,0,0,1-.17,0h-.66l1.41-3.64h.86Zm-1.34-1.39-.34-1-.08-.22c0-.09-.05-.18-.08-.28,0,.1-.05.2-.08.28s-.06.16-.08.23l-.34,1Z" fill="#000a1f" />
            </g>
            <g id="optionB"
              opacity={outcome === "b" ? 1 : 0.2}>
              <path d="M91.51,59.41V56.64h5l-6.27-6.27L84,56.64h5v2.85a4,4,0,0,0,0,7.45V67A3.9,3.9,0,0,1,85,70.93H36.49a6.51,6.51,0,0,0-6.5,6.5V93h2.6V77.43a3.91,3.91,0,0,1,3.9-3.9H85A6.5,6.5,0,0,0,91.51,67h0a4,4,0,0,0,0-7.62Z" fill="#fff" />
              <path d="M90.46,61.42a2.41,2.41,0,0,1,.64.07,1.15,1.15,0,0,1,.43.2.84.84,0,0,1,.25.31,1,1,0,0,1,.07.41.85.85,0,0,1,0,.23.7.7,0,0,1-.11.21.91.91,0,0,1-.2.19,1.22,1.22,0,0,1-.28.14,1,1,0,0,1,.53.28.71.71,0,0,1,.18.5,1.08,1.08,0,0,1-.09.44,1,1,0,0,1-.27.35,1.26,1.26,0,0,1-.43.23,2,2,0,0,1-.58.08H89.12V61.42Zm-.5.63V63h.44a1,1,0,0,0,.25,0,.36.36,0,0,0,.19-.07.24.24,0,0,0,.12-.14A.45.45,0,0,0,91,62.5a.46.46,0,0,0,0-.21.24.24,0,0,0-.1-.14.38.38,0,0,0-.17-.07.88.88,0,0,0-.24,0Zm.6,2.37a.7.7,0,0,0,.27,0,.42.42,0,0,0,.17-.11.36.36,0,0,0,.08-.15,1,1,0,0,0,0-.17.48.48,0,0,0,0-.18.22.22,0,0,0-.09-.14.48.48,0,0,0-.16-.08,1,1,0,0,0-.27,0H90v.9Z" fill="#000a1f" />
            </g>
          </g>

          <g className="road-animate">
            <CentralReservation />
            <CrossingSide
              lane={"left"}
              x="10"
              y="0"
              occupants={scenario.left.occupants}
              barrier={scenario.left.barrier}
              inDanger={inDanger === "left" ? true : false}
            />
            <CrossingSide
              lane={"right"}
              x="70"
              y="0"
              occupants={scenario.right.occupants}
              barrier={scenario.right.barrier}
              inDanger={inDanger === "right" ? true : false}
            />
            />
          </g>

          <Car
            x="4"
            y="1"
            occupants={scenario.car.occupants}
            barrier={scenario.right.barrier}
            inDanger={inDanger === "car" ? true : false}
          />

          <rect
            className="sceneSVG-a"
            width="60"
            height="120"
            x="0"
            y="0"
            onClick={() => selectOption("question_" + scenarioNumber, "a")}
          />
          <rect
            className="sceneSVG-b"
            width="60"
            height="120"
            x="60"
            y="0"
            onClick={() => selectOption("question_" + scenarioNumber, "b")}
          />
        </svg>
      </figure>
    );
  }
}

export default SceneSVG;
