import React, { Component } from "react";
import AdultIcon from "./../assets/AdultIcon";
import ChildIcon from "./../assets/ChildIcon";
import ElderlyIcon from "./../assets/ElderlyIcon";
import DogIcon from "./../assets/DogIcon";


class Occupant extends Component {
  render() {
    const inDanger = this.props.inDanger;
    const type = this.props.type;
    const x = this.props.x;
    const y = this.props.y;
    return (
      <svg className="occupant-animate" viewBox="0 0 20 20" x={x} y={y} width="20" height="20">
        <rect className="occupant-container" y="1.79" width="18.26" height="18.26" rx="0.5" ry="0.5" fill={inDanger ? "#FF002B" : "#1cf3a0"} />
        {type === "adult" && <AdultIcon />}
        {type === "child" && <ChildIcon />}
        {type === "elder" && <ElderlyIcon />}
        {type === "dog" && <DogIcon />}


        {inDanger &&
          <g className="alert">
            <path d="M19.66,4.79,17.06.49a.56.56,0,0,0-1,0l-2.6,4.3a.56.56,0,0,0,0,.57.56.56,0,0,0,.49.29h5.19a.57.57,0,0,0,.5-.29A.56.56,0,0,0,19.66,4.79Z" fill="#f7931e" />
            <path d="M19.17,5.91H14a.83.83,0,0,1-.71-1.25L15.87.35a.83.83,0,0,1,.71-.4.84.84,0,0,1,.71.4l2.59,4.31a.8.8,0,0,1,0,.83A.82.82,0,0,1,19.17,5.91ZM16.58.47a.33.33,0,0,0-.27.15l-2.59,4.3a.3.3,0,0,0,.26.47h5.19a.32.32,0,0,0,.27-.15.31.31,0,0,0,0-.32L16.84.62A.31.31,0,0,0,16.58.47Z" />
            <path d="M16.31,4.31a.22.22,0,0,1,0-.11.24.24,0,0,1,.06-.09l.09-.06.12,0,.11,0,.09.06.07.09a.43.43,0,0,1,0,.11.36.36,0,0,1,0,.11.22.22,0,0,1-.07.09.15.15,0,0,1-.09.06.2.2,0,0,1-.11,0,.25.25,0,0,1-.12,0,.24.24,0,0,1-.09-.06.15.15,0,0,1-.06-.09A.2.2,0,0,1,16.31,4.31Zm.53-2.14v.94a1.51,1.51,0,0,1,0,.3c0,.1,0,.2,0,.31h-.35c0-.11,0-.21-.05-.31s0-.2,0-.3V2.17Z" />
          </g>
        }
      </svg>
    );
  }
}

export default Occupant;
