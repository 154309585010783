import React from "react";

const ElderlyIcon = () => (

  <g>
    <rect x="7.04" y="11.37" width="2.12" height="6.42" rx="1.06" ry="1.06" fill="#fff" />
    <path d="M8.1,18a1.28,1.28,0,0,1-1.29-1.28v-4.3a1.29,1.29,0,0,1,2.57,0v4.3A1.28,1.28,0,0,1,8.1,18Zm0-6.41a.84.84,0,0,0-.84.83v4.3a.84.84,0,0,0,1.68,0v-4.3A.84.84,0,0,0,8.1,11.6Z" />
    <rect x="9.2" y="11.37" width="2.12" height="6.42" rx="1.06" ry="1.06" fill="#fff" />
    <path d="M10.26,18A1.28,1.28,0,0,1,9,16.73v-4.3a1.29,1.29,0,0,1,2.57,0v4.3A1.28,1.28,0,0,1,10.26,18Zm0-6.41a.84.84,0,0,0-.84.83v4.3a.84.84,0,0,0,1.68,0v-4.3A.83.83,0,0,0,10.26,11.6Z" />
    <rect x="6.63" y="6.2" width="5.16" height="8.36" rx="2.58" ry="2.58" fill="#fff" />
    <path d="M9.2,14.79A2.81,2.81,0,0,1,6.4,12V8.78a2.8,2.8,0,0,1,5.6,0V12A2.8,2.8,0,0,1,9.2,14.79Zm0-8.37A2.36,2.36,0,0,0,6.85,8.78V12a2.36,2.36,0,0,0,4.71,0V8.78A2.36,2.36,0,0,0,9.2,6.42Z" />
    <circle cx="9.21" cy="6.46" r="2.22" fill="#fff" />
    <path d="M9.21,8.91a2.45,2.45,0,1,1,2.45-2.45A2.45,2.45,0,0,1,9.21,8.91Zm0-4.45a2,2,0,1,0,2,2A2,2,0,0,0,9.21,4.46Z" />
    <polygon points="12.34 16.82 12.08 16.6 13.14 15.33 12.05 10.74 12.37 10.66 13.49 15.33 13.45 15.48 12.34 16.82" />
    <path d="M12.11,17.77a.18.18,0,0,1-.17-.17V10.53a.17.17,0,0,1,.34,0V17.6A.18.18,0,0,1,12.11,17.77Z" />
    <path d="M6.1,17.77a.18.18,0,0,1-.17-.17V10.53a.17.17,0,0,1,.34,0V17.6A.18.18,0,0,1,6.1,17.77Z" />
    <path d="M13.84,17.1a.17.17,0,0,1-.16-.12L13,14.75a.18.18,0,0,1,.11-.21.17.17,0,0,1,.21.12L14,16.88a.16.16,0,0,1-.11.21Z" />
    <polygon points="5.92 16.82 4.81 15.48 4.77 15.33 5.88 10.66 6.21 10.74 5.12 15.33 6.18 16.6 5.92 16.82" />
    <path d="M4.42,17.1h0a.16.16,0,0,1-.11-.21l.67-2.22a.16.16,0,0,1,.2-.12.18.18,0,0,1,.12.21L4.58,17A.17.17,0,0,1,4.42,17.1Z" />
    <path d="M12.06,16.88h-6a.17.17,0,0,1,0-.34h6a.17.17,0,0,1,0,.34Z" />
    <path d="M12.06,10.86h-6a.17.17,0,0,1-.17-.16.17.17,0,0,1,.17-.17h6a.17.17,0,0,1,.17.17A.17.17,0,0,1,12.06,10.86Z" />
    <path d="M12.21,17.15a.42.42,0,0,1-.28-.1.45.45,0,0,1-.06-.63l1-1.16L11.78,10.8a.45.45,0,0,1,.33-.54.44.44,0,0,1,.53.33l1.12,4.68a.45.45,0,0,1-.09.39L12.55,17A.43.43,0,0,1,12.21,17.15Z" />
    <path d="M12.11,18a.44.44,0,0,1-.44-.44V10.53a.45.45,0,1,1,.89,0V17.6A.44.44,0,0,1,12.11,18Z" />
    <path d="M6.1,18a.44.44,0,0,1-.45-.44V10.53a.45.45,0,0,1,.45-.45.44.44,0,0,1,.44.45V17.6A.43.43,0,0,1,6.1,18Z" />
    <path d="M13.84,17.38a.46.46,0,0,1-.43-.32l-.66-2.23a.44.44,0,1,1,.85-.25l.67,2.22a.45.45,0,0,1-.3.56Z" />
    <path d="M6.05,17.15A.41.41,0,0,1,5.71,17L4.59,15.66a.45.45,0,0,1-.09-.39l1.11-4.68a.46.46,0,0,1,.54-.33.45.45,0,0,1,.33.54L5.42,15.26l1,1.16a.45.45,0,0,1-.06.63A.42.42,0,0,1,6.05,17.15Z" />
    <path d="M4.42,17.38l-.13,0A.45.45,0,0,1,4,16.8l.67-2.22a.44.44,0,0,1,.85.25l-.67,2.23A.44.44,0,0,1,4.42,17.38Z" />
    <path d="M12.06,17.15h-6a.45.45,0,1,1,0-.89h6a.45.45,0,0,1,0,.89Z" />
    <path d="M12.06,11.14h-6a.45.45,0,1,1,0-.89h6a.45.45,0,0,1,0,.89Z" />
    <path d="M12.21,16.82a.11.11,0,0,1-.07,0,.11.11,0,0,1,0-.15l1.08-1.3-1.1-4.62a.11.11,0,0,1,.08-.13.11.11,0,0,1,.14.08l1.11,4.68a.1.1,0,0,1,0,.09L12.3,16.78A.12.12,0,0,1,12.21,16.82Z" fill="#fff" />
    <path d="M12.11,17.71A.11.11,0,0,1,12,17.6V10.53a.11.11,0,1,1,.22,0V17.6A.11.11,0,0,1,12.11,17.71Z" fill="#fff" />
    <path d="M6.1,17.71A.11.11,0,0,1,6,17.6V10.53a.11.11,0,0,1,.22,0V17.6A.11.11,0,0,1,6.1,17.71Z" fill="#fff" />
    <path d="M13.84,17a.12.12,0,0,1-.11-.08l-.66-2.22a.11.11,0,0,1,.21-.07L14,16.9a.12.12,0,0,1-.08.14Z" fill="#fff" />
    <path d="M6.05,16.82a.11.11,0,0,1-.09,0L4.85,15.44a.1.1,0,0,1,0-.09l1.11-4.68a.1.1,0,0,1,.13-.08.11.11,0,0,1,.09.13l-1.1,4.62,1.07,1.3a.1.1,0,0,1,0,.15A.11.11,0,0,1,6.05,16.82Z" fill="#fff" />
    <path d="M4.42,17h0a.11.11,0,0,1-.08-.14L5,14.67a.11.11,0,0,1,.21.07L4.52,17A.1.1,0,0,1,4.42,17Z" fill="#fff" />
    <path d="M12.06,16.82h-6a.11.11,0,0,1,0-.22h6a.11.11,0,1,1,0,.22Z" fill="#fff" />
    <path d="M12.06,10.81h-6a.11.11,0,1,1,0-.22h6a.11.11,0,0,1,0,.22Z" fill="#fff" />
    <rect x="11.01" y="9.77" width="1.9" height="1.9" rx="0.95" ry="0.95" fill="#fff" />
    <path d="M12,11.89a1.17,1.17,0,0,1,0-2.34,1.17,1.17,0,1,1,0,2.34ZM12,10a.73.73,0,0,0-.72.73.72.72,0,0,0,.72.72.73.73,0,0,0,.73-.72A.73.73,0,0,0,12,10Z" />
    <rect x="5.49" y="9.97" width="1.9" height="1.9" rx="0.95" ry="0.95" fill="#fff" />
    <path d="M6.43,12.09A1.17,1.17,0,1,1,7.6,10.92,1.17,1.17,0,0,1,6.43,12.09Zm0-1.89a.72.72,0,0,0-.72.72.73.73,0,0,0,.72.73.73.73,0,0,0,.73-.73A.73.73,0,0,0,6.43,10.2Z" />
  </g >

);

export default (ElderlyIcon);