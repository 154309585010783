import React, { Component } from "react";
import { connect } from "react-redux";
import "./App.css";
import Scenario from "./pages/Scenario";
import Intro from "./pages/Intro";
import AboutMe from "./pages/AboutMe";
import AboutProject from "./pages/AboutProject";
import Instructions from "./pages/Instructions";
import Results from "./pages/Results";
import TrackSVG from "./assets/TrackSVG";

import { nextPage } from "./actions/nextPage";

class App extends Component {

  componentWillUpdate() {
    window.scrollTo(0, 0);
  }

  render() {
    const { page, doNextPage } = this.props;
    const trackMoving = page !== 4 ? "horzLine active" : "horzLine";
    const trackRotate = page === 1 ? "rotate active" : "rotate";
    const radialMask = page === 4 ? false : true;
    const transformAngle = page === 1 || page === 4 ? 0 : 10;
    return (
      <div className="siteWrapper">
        <div className="siteContent-wrapper">
          {page === 1 && <Intro doNextPage={doNextPage} />}
          {page === 2 && <AboutProject doNextPage={doNextPage} />}
          {page === 3 && <Instructions doNextPage={doNextPage} />}
          {page === 4 && <Scenario doNextPage={doNextPage} />}
          {page === 5 && <AboutMe doNextPage={doNextPage} />}
          {page === 6 && <Results />}

          <TrackSVG
            transformAngle={transformAngle}
            radialMask={radialMask}
            trackMoving={trackMoving}
            trackRotate={trackRotate}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  page: state.navStore.page
});

const mapDispatchToProps = dispatch => ({
  doNextPage: () => dispatch(nextPage())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
