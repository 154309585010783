import { scenarios } from "../pages/scenarios";

export const calculatePreferences = state => {
  const { values } = state;

  // start with no preferences
  let preferences = {
    inCarVsRoadUser: 0,
    youngVsOld: 0,
    humanVsAnimal: 0,
    fewVsMany: 0
  };

  const justQuestionResponses = [
    values.question_1,
    values.question_2,
    values.question_3,
    values.question_4,
    values.question_5,
    values.question_6,
    values.question_7,
    values.question_8,
    values.question_9,
    values.question_10
  ];

  //console.log(justQuestionResponses);

  for (let i = 0; i < justQuestionResponses.length; i++) {
    const response = justQuestionResponses[i];
    const outcome = scenarios[i][response];

    //console.log(outcome);

    if (outcome.inCarVsRoadUser) {
      preferences.inCarVsRoadUser =
        preferences.inCarVsRoadUser + outcome.inCarVsRoadUser;
    }
    if (outcome.fewVsMany) {
      preferences.fewVsMany = preferences.fewVsMany + outcome.fewVsMany;
    }
    if (outcome.youngVsOld) {
      preferences.youngVsOld = preferences.youngVsOld + outcome.youngVsOld;
    }
    if (outcome.humanVsAnimal) {
      preferences.humanVsAnimal =
        preferences.humanVsAnimal + outcome.humanVsAnimal;
    }
  }

  state.values.inCarVsRoadUser = preferences.inCarVsRoadUser;
  state.values.fewVsMany = preferences.fewVsMany;
  state.values.youngVsOld = preferences.youngVsOld;
  state.values.humanVsAnimal = preferences.humanVsAnimal;
  return state;
};
