import { combineReducers } from "redux";
import navReducer from "./NavReducer";
import { reducer as formReducer } from "redux-form";
import { calculatePreferences } from "../functions/calculatePreferences";

export default combineReducers({
  navStore: navReducer,
  form: formReducer.plugin({
    userResponses: (state, action) => {
      switch (action.type) {
        case "RECALCULATE_PREFS":
          state = calculatePreferences(state);
          return state;
        default:
          return state;
      }
    }
  })
});
